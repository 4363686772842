import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import HeadingMain from '../components/HeadingMain'
import NewsBox from '../components/NewsBox'
import SEO from '../components/SEO'

const Container = styled.div`
  margin-top: ${p => p.theme.marginToTop};
  margin-bottom: 120px;
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
`

const NewsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap: 80px;
  align-items: flex-start;
  @media (max-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`

class Neuigkeiten extends React.Component {
  constructor(props) {
    super(props)
    this.neuigkeitenRef = React.createRef()
  }

  componentDidMount() {
    const links = this.neuigkeitenRef.current.getElementsByTagName('a')
    const linksArray = Array.from(links)
    linksArray.forEach(link => {
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
    })
  }

  render() {
    const { data } = this.props
    const { edges } = data.newsBoxHeaderImages
    return (
      <>
        <SEO title="Neuigkeiten" description="Aktuelle Auszeichnungen von Falstaff bis zu A la Carte und mehr." />
        <Container>
          <HeadingMain mainHeading="Neuigkeiten" subHeading="Auszeichnungen und Neues vom Weingut Göschl" />
          <NewsContainer ref={this.neuigkeitenRef}>
            {edges.map(({ node }) => {
              return (
                <NewsBox
                  image={node.frontmatter.image}
                  date={node.frontmatter.date}
                  key={node.id}
                  title={node.frontmatter.title}
                  copyright={node.frontmatter.copyright}
                  text={node.html}
                />
              )
            })}
          </NewsContainer>
        </Container>
      </>
    )
  }
}

export default Neuigkeiten

export const query = graphql`
  query {
    newsBoxHeaderImages: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/news/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            copyright
            image {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
          html
        }
      }
    }
  }
`
