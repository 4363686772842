export function formatDate(year, month) {
  let monthInWords = ''
  switch (month) {
    case '01':
      monthInWords = 'Januar'
      break
    case '02':
      monthInWords = 'Februar'
      break
    case '03':
      monthInWords = 'März'
      break
    case '04':
      monthInWords = 'April'
      break
    case '05':
      monthInWords = 'Mai'
      break
    case '06':
      monthInWords = 'Juni'
      break
    case '07':
      monthInWords = 'Juli'
      break
    case '08':
      monthInWords = 'August'
      break
    case '09':
      monthInWords = 'September'
      break
    case '10':
      monthInWords = 'Oktober'
      break
    case '11':
      monthInWords = 'November'
      break
    case '12':
      monthInWords = 'Dezember'
      break
    default:
      monthInWords = ''
  }
  return `${monthInWords} ${year}`
}
