import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import { formatDate } from '../utilities/formatDate'

import StyledLink from './styles/StyledLink'

import arrow from '../images/navbar-arrow.svg'

const Container = styled.div`
  /* box-shadow: 0 1px 7px rgba(0, 0, 0, 0.05); */
  box-shadow: 0 4px 20px 0 rgba(100, 100, 100, 0.3);
  /* box-shadow: 0 4px 28px -2px rgba(0, 0, 0, 0.06); */
  border-radius: 6px;
  /* height: 580px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.22s;
  :hover {
    transform: translateY(-1px);
    box-shadow: 0 8px 38px rgba(100, 100, 100, 0.3);
  }
  /* FOR NON-GRID-SUPPORT */
  width: 36%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  @supports (display: grid) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
`

const HeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: 230px;
  /* object-fit: contain; */
`
const PreviewImage = styled.img`
  width: 100%;
  height: 230px;
  object-fit: cover;
`

const BlankWhite = styled.div`
  width: 100%;
  height: 230px;
`

const ContentContainer = styled.div`
  position: relative;
  padding: 26px 40px 40px 40px;
  min-height: ${p => (p.isExpanded ? '340px' : '267px')};
  max-height: ${p => (p.isExpanded ? '10000px' : '240px')};
  overflow: hidden;
  border-top: 1px solid ${p => p.theme.lightGray};
  /* :hover {
    max-height: 1000px;
  } */
`

const Heading = styled.h1`
  font-size: 23px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  line-height: 1.2;
  color: ${p => p.theme.textColorDark};
  letter-spacing: 1.2px;
  margin-bottom: 21px;
`

const Date = styled.div`
  font-size: 10px;
  letter-spacing: 1.2px;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  margin-bottom: 4px;
`

const Content = styled.div`
  p {
    margin-bottom: 23px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  strong {
    font-weight: 600;
    color: ${p => p.theme.textColorDark};
  }
  a {
    position: relative;
    z-index: 2;
    :after {
      content: '';
      display: block;
      position: absolute;
      left: 0px;
      bottom: 1px;
      background-color: ${p => p.theme.primaryColorLighter};
      width: 100%;
      height: 6px;
      z-index: -1;
    }
  }
`

const Dots = styled.div`
  height: 40px;
  padding: 0 40px;
  ${p => p.isExpanded && 'display: none;'}
  border-bottom: 1px solid ${p => p.theme.lightGray};
`

const DotsInner = styled.button``

const ReadMoreContainer = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 40px;
  height: 60px;
  ${p => p.isExpanded && 'display: none;'}
`

const DownArrow = styled.div`
  margin-bottom: 2px;
`

const DownArrowImg = styled.img`
  width: 14px;
`

const Copyright = styled.div`
  position: absolute;
  top: 2px;
  font-size: 11px;
  color: #b3b3b3;
  @media (max-width: 500px) {
    left: 2px;
  }
`

const ContentInnerContainer = styled.div`
  ${p => p.isExpanded && 'min-height: 300px;'}
`

class NewsBox extends React.Component {
  constructor(props) {
    super(props)
    this.contentInnerContainerRef = React.createRef()
  }
  state = {
    isExpanded: false,
    showButton: false,
  }

  handleExpandContent = () => {
    this.setState({ isExpanded: true })
  }

  componentDidMount() {
    if (this.contentInnerContainerRef.current.clientHeight < 300) {
      this.setState({ isExpanded: true })
    }
  }

  render() {
    const { title, text, date, image, previewImage, copyright } = this.props
    let headerImage = undefined
    if (image && image.childImageSharp && image.childImageSharp.gatsbyImageData) {
      headerImage = image.childImageSharp.gatsbyImageData
    }
    let year = ''
    let month = ''
    if (date) {
      year = date.substr(0, 4)
      month = date.substr(5, 2)
    }

    return (
      <Container>
        {headerImage ? (
          <HeaderImage alt={title} image={headerImage} />
        ) : previewImage ? (
          <PreviewImage src={previewImage} />
        ) : (
          <BlankWhite />
        )}
        <ContentContainer isExpanded={this.state.isExpanded}>
          <ContentInnerContainer isExpanded={this.state.isExpanded} ref={this.contentInnerContainerRef}>
            {copyright && <Copyright>&copy; {copyright}</Copyright>}
            <Date>{formatDate(year, month).toUpperCase()}</Date>
            <Heading>{title}</Heading>
            <Content dangerouslySetInnerHTML={{ __html: text }} />
          </ContentInnerContainer>
        </ContentContainer>
        <Dots isExpanded={this.state.isExpanded}>
          <DotsInner onClick={this.handleExpandContent}>...</DotsInner>
        </Dots>
        <ReadMoreContainer isExpanded={this.state.isExpanded} onClick={this.handleExpandContent}>
          <StyledLink style={{ marginBottom: '2px' }}>Mehr lesen</StyledLink>
          <DownArrow>
            <DownArrowImg src={arrow} alt="" />
          </DownArrow>
        </ReadMoreContainer>
      </Container>
    )
  }
}

export default NewsBox
